<template>
  <!--begin::List Widget 1-->
    <div class="info-list row">
    <template v-for="(item, i) in infoItems">
      <div class="info-item col-6 col-md-6 col-lg-4 mb-4" v-bind:key="i">
        <div
          class="info-box d-flex flex-column justify-content-between px-8 py-6 rounded-sm h-100"
          v-bind:class="[item.bg === 'white' ? 'bg-white border' : `bg-${item.bg}`]"
        >
          <span
            class="info-symbol svg-icon svg-icon-3x d-block my-2"
            v-bind:class="`svg-icon-${item.style}`"
          >
            <inline-svg :src="`${item.svg}`"/>
          </span>
          <div class="info-content">
            <span
              class="info-title font-weight-normal font-size-h5"
              v-bind:class="`text-${item.style}`"
            >
              {{ item.title }}
            </span>
            <span
              class="info-desc text-white font-weight-bold font-size-lg"
              v-bind:class="`text-${item.style}`"
            >
              {{ item.desc }}
            </span>
          </div>
        </div>
      </div>
    </template>
    </div>
  <!--end: List Widget 1-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "FarmBasicItems",
  props:{
    info: Object
  },
  data() {
    return {
      i18n : {
        name: i18n.t('farm.name'),
        address: i18n.t('farm.address'),
        province: i18n.t('farm.province'),
        cultivationType: i18n.t('farm.cultivationType'),
        farmCode: i18n.t('farm.farmCode'),
        operationStatus: i18n.t('farm.operationStatus'),
      }
    };
  },
  computed: {
    infoItems(){
      return [
        {
          title: this.i18n.name,
          desc: this.info.farmNm,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Text/Article.svg",
          style: "white",
          bg: "danger"
        },
        {
          title: this.i18n.address,
          desc: this.info.farmAddr + ' ' + (this.info.farmAddrDetail || ''),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Map/Marker1.svg",
          style: "white",
          bg: "info"
        },
        {
          title: this.i18n.province,
          desc: this.info.sidoGb,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Map/Compass.svg",
          style: "white",
          bg: "success"
        },
        {
          title: this.i18n.cultivationType,
          desc: this.info.cultivateType,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Flower1.svg",
          style: "dark",
          bg: "white"
        },
        {
          title: this.i18n.farmCode,
          desc: (this.info.farmCd || '-'),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Code/Code.svg",
          style: "white",
          bg: "primary"
        },
        {
          title: this.i18n.operationStatus,
          desc: (this.info.operationSt || '-'),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Circle.svg",
          style: "white",
          bg: "dark"
        }
      ]
    }
  }


};
</script>
