<template>
  <!--begin::Info Basic-->
  <div class="card card-custom card-stretch gutter-b info-basic">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.basicInfo}}
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <div class="row">
        <div v-show="diagnosisModal==false" :class="`col-mb-12 mb-4 col-xl-4 rounded text-white font-weight-bold`">
          <div :class="`text font-size-h5 row rounded m-0 py-2 h-100 w-100 ${envBg}`" style="margin-right: 12.5px; margin-left: 12.5px;">
            <div class="font-size-h5 pt-2 px-8 col-9">{{i18n.farmDiagnosis}}</div>
              <div class="col-3 pt-2">
                <template>
                  <div>
                    <b-form-checkbox v-model="diagnosisModal" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                </template>
              </div>
              <div class="col-12">
              <div v-if="totalPercentageDiagnosis() === 'GOOD'" class="d-flex align-items-center flex-column mb-5">
                <b-icon icon="emoji-smile" class="rounded-circle mb-5" font-scale="10"></b-icon>
                <div>Good</div>
              </div>
              <div v-if="totalPercentageDiagnosis() === 'NOT_BAD'" class="d-flex align-items-center flex-column mb-5">
                <b-icon icon="emoji-neutral" class="rounded-circle mb-5" font-scale="10"></b-icon>
                <div>Not Bad</div>
              </div>
              <div v-if="totalPercentageDiagnosis() === 'BAD'" class="d-flex align-items-center flex-column mb-5">
                <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="10"></b-icon>
                <div>Bad</div>
              </div>
              <div v-if="totalPercentageDiagnosis() === 'NO_DATA'" class="d-flex align-items-center flex-column mb-5">
                <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="10"></b-icon>
                <div>No Data</div>
              </div>
              </div>
              <small class="text-left font-weight-bold p-2 text-truncate" style="font-size: small;">{{i18n.diagnosisStandards}}: [~ 20% Good], [20 ~ 40% Not Bad], [40% ~ Bad]</small>
            </div>
        </div>

        <div v-show="diagnosisModal==true" :class="`col-mb-12 mb-4 col-xl-4 rounded text-white font-weight-bold`">
          <div :class="`text font-size-h5 mb-4 row rounded m-0 py-2 h-100 w-100 ${envBg}`" style="z-index: 5;">
            <div class="font-size-h5 mt-2 ml-3 col-6">{{i18n.farmDiagnosis}}</div>
            <div class="col-2 mt-1 mr-6">
              <span v-if="totalPercentageDiagnosis() === 'GOOD'" class="d-flex align-items-center flex-column">
                <b-icon icon="emoji-smile" class="rounded-circle" font-scale="2"></b-icon>
              </span>
              <span v-if="totalPercentageDiagnosis() === 'NOT_BAD'" class="d-flex align-items-center flex-column">
                <b-icon icon="emoji-neutral" class="rounded-circle mb-5" font-scale="2"></b-icon>
              </span>
              <span v-if="totalPercentageDiagnosis() === 'BAD'" class="d-flex align-items-center flex-column">
                <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="2"></b-icon>
              </span>
              <span v-if="totalPercentageDiagnosis() === 'NO_DATA'" class="d-flex align-items-center flex-column">
              <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="2"></b-icon>
              </span>
            </div>
            <div class="col-2">
              <template>
                  <div>
                    <b-form-checkbox v-model="diagnosisModal" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
              </template>
            </div>

            <div class="card-body px-8 py-0 pt-5" style="font-size: small">
            <!--begin::Item-->
            <div class="d-flex align-items-center row mb-4">
              <div class="d-flex flex-column col-6 text-hover-primary">
                <div>{{state}}</div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.currentStatus}}
                </span>
              </div>
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{Math.floor(dataCount/1440*100)}}%({{dataCount}}){{i18n.countDay}}
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.totalData}}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center row mb-4">
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{totalPer}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.totalOutOfRange}}
                </span>
              </div>
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{tempPer}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.tempOutOfRange}}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center row mb-4">
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{humidityPer}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.humOutOfRange}}
                </span>
              </div>
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{co2Per}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.co2OutOfRange}}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center row mb-4">
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{ecPer}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.ecOutOfRange}}
                </span>
              </div>
              <div class="d-flex flex-column col-6">
                <div class="farm-header font-weight-bolder text-white text-hover-primary">
                {{phPer}}%
                </div>
                <span class="farm-text font-size-sm font-weight-bold text-muted">
                {{i18n.phOutOfRange}}
                </span>
              </div>
            </div>
            <!--end::Item-->
          </div>
          </div>
        </div>
        <div class="col-mb-12 col-xl-8">
          <farm-basic-items
              :info="info"
          ></farm-basic-items>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--begin::Info Basic-->
</template>

<script>
import {ACT_GET_MY_FARM, ACT_GET_MY_CONNECTS, ACT_FARM_TOTAL_STATICS} from '@/core/services/variable';
import FarmBasicItems from "@/view/content/farm/FarmBasicItems.vue";
// import FarmBasicMap from "@/view/content/farm/FarmBasicMap.vue";
import i18n from '@/core/plugins/vue-i18n.js';
import {lengthCheck, getItem, collectionsCheck,} from '@/core/services/funcs';


export default {
  name: "FarmBasic",
  beforeMount(){
    this.$store.dispatch(ACT_GET_MY_FARM).then((myFarm)=>{
      if(lengthCheck(myFarm)){
          const myFarmItem = getItem(myFarm)
          this.myFarm = myFarmItem
        }
      this.$store.dispatch(ACT_GET_MY_CONNECTS).then((myConnects)=>{
        if(lengthCheck(myConnects)){
          const myConnectsItem = getItem(myConnects)
          if(collectionsCheck(myConnectsItem.farmConnects, 'seq')) {
            this.connectsCount = myConnectsItem.farmConnects.length
          }
        }

        this.$store.dispatch(ACT_FARM_TOTAL_STATICS, this.myFarm.fno).then(resp =>{
          if(lengthCheck(resp)){
            const statics = getItem(resp)
            this.dataCount = (statics.dataCount/this.connectsCount).toFixed(0)
            this.tempPer = statics.tempPer
            this.humidityPer = statics.humidityPer
            this.co2Per = statics.co2Per
            this.ecPer = statics.ecPer
            this.phPer = statics.phPer
            this.totalPer = statics.totalPer
          }
        })
      })
    })
  },
  props: {
    info: Object,
  },
  components: {
    FarmBasicItems,
  },
  data(){
    return{
      i18n: {
        basicInfo: i18n.t('farm.basicInfo'),
        farmDiagnosis: i18n.t('farm.farmDiagnosis'),
        currentStatus: i18n.t('farm.currentStatus'),
        totalData: i18n.t('farm.totalData'),
        countDay: i18n.t('farm.countDay'),
        totalOutOfRange: i18n.t('farm.totalOutOfRange'),
        tempOutOfRange: i18n.t('farm.tempOutOfRange'),
        humOutOfRange: i18n.t('farm.humOutOfRange'),
        co2OutOfRange: i18n.t('farm.co2OutOfRange'),
        ecOutOfRange: i18n.t('farm.ecOutOfRange'),
        phOutOfRange: i18n.t('farm.phOutOfRange'),
        diagnosisStandards: i18n.t('farm.diagnosisStandards')
      },
      myFarm: {},
      currentSystemId: 0,
      farmConnects: [],
      weeklyStatistics: [],
      startYmd: '',
      endYmd: '',
      envBg: 'bg-success',
      state: '',
      diagnosisModal: false,

      dataCount: null,
      tempPer: null,
      humidityPer: null,
      co2Per: null,
      ecPer: null,
      phPer: null,
      totalPer: null,
      connectsCount: null,
    }
  },
  methods:{
    totalPercentageDiagnosis() {
      const totalPercentage = this.totalPer
      if(totalPercentage == null) {
        this.envBg = 'bg-warning'
        this.state= 'NO_DATA'
				return 'NO_DATA';
			}else if (totalPercentage <= 20) {
        this.envBg = 'bg-success'
        this.state= 'GOOD'
				return 'GOOD';
			} else if (totalPercentage <= 40) {
        this.envBg = 'bg-success'
        this.state= 'NOT_BAD'
				return 'NOT_BAD';
			} else if(totalPercentage > 40){
        this.envBg = 'bg-warning'
        this.state= 'BAD'
				return 'BAD';
      }
		},
  },
};
</script>