<template>
  <!--begin::Farm Facility-->
  <div class="card card-custom card-stretch gutter-b farm-info-scale">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.title}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Wood2.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-light">
      <template v-for="(item, i) in facilityItems">
        <dl class="text-group row mb-4" v-bind:key="i">
            <dt class="title col-6">
              <span class="title font-weight-bold font-size-lg">{{ item.title }}</span>
            </dt>
            <dd class="description col-6">
              <span class="text">{{ item.desc }}</span>
            </dd>
        </dl>
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Facility-->
</template>

<script>
import { getValueWithDefault, getValueWithComma } from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmFacility",
  data(){
    return{
      i18n: {
        title: i18n.t('farm.facility.title'),
        layerCount: i18n.t('farm.facility.layerCount'),
        rowCount: i18n.t('farm.facility.rowCount'),
        rackType: i18n.t('farm.facility.rackType'),
        bedStandard: i18n.t('farm.facility.bedStandard'),
        bedCount: i18n.t('farm.facility.bedCount'),
        rackCount: i18n.t('farm.facility.rackCount'),
        seedRackCount: i18n.t('farm.facility.seedRackCount'),
        transplantRackCount: i18n.t('farm.facility.transplantRackCount'),
        lastFeedRackCount: i18n.t('farm.facility.lastFeedRackCount'),
        portCount: i18n.t('farm.facility.portCount'),
      }
    }
  },
  props: {
    facility: Object
  },
  computed: {
    facilityItems(){
      return [
        { title: this.i18n.layerCount, desc: getValueWithComma(this.facility.layerCount) },
        { title: this.i18n.rowCount, desc: getValueWithComma(this.facility.rowCount) },
        { title: this.i18n.rackType, desc: this.getRackTypes() },
        { title: this.i18n.bedStandard, desc: getValueWithDefault(this.facility.bedStandards, '-') },
        { title: this.i18n.bedCount, desc: this.facility.bedCount },
        { title: this.i18n.rackCount, desc: getValueWithComma(this.facility.rackCount) },
        { title: this.i18n.seedRackCount, desc: getValueWithComma(this.facility.seedRackCount) },
        { title: this.i18n.transplantRackCount, desc: getValueWithComma(this.facility.transplantRackCount) },
        { title: this.i18n.lastFeedRackCount, desc: getValueWithComma(this.facility.lastfeedRackCount) },
        { title: this.i18n.portCount, desc: getValueWithComma(this.facility.potCount) }
      ]
    }
  },
  methods: {
    getRackTypes(){
      const rackTypeList = [];
      if(this.facility){
        for(let i = 1; i <=5; i++){
          if(this.facility['rackType' + i]){
            rackTypeList.push(this.facility['rackType' + i]);
          }
        }
      }
      if(rackTypeList.length) return rackTypeList.join(', ');
      return '-';
    }
  }
};
</script>
