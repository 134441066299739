<template>
  <!--begin::Farm Manager-->
  <div class="card card-custom card-stretch gutter-b farm-info-scale">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.title}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Address-card.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-light">
      <template v-for="(item, i) in managerItems">
        <dl class="text-group row mb-4" v-bind:key="i">
            <dt class="title col-6">
              <span class="title font-weight-bold font-size-lg">{{ item.title }}</span>
            </dt>
            <dd class="description col-6">
              <span class="text">{{ item.desc }}</span>
            </dd>
        </dl>
      </template>
      <div class="float-right"><button class="btn btn-secondary" @click="managerEdit()"><span class='icon-xs fas fa-pen'></span></button></div>
    </div>
    <!--end::Body-->
    <!-- begin::companyModal -->
    <b-modal
      v-model="managerModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.editTitle}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="managerModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.manager}}</span>
          </label>
         <div class="col-sm-4">
            <input v-model="farmManagerName" type="text" class="farm-form-control form-control">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.managerMobilePhone}}</span>
          </label>
         <div class="col-sm-4">
            <input v-model="farmManagerPhoneNo" type="text" class="farm-form-control form-control">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.managerEmail}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="farmManagerEmail" type="text" class="farm-form-control form-control">
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveManager()"><span>{{i18n.edit}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="managerModal = false"><span class="">{{i18n.cancel}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::companyModal -->
  </div>
  <!--end: Farm Manager-->
</template>

<script>
import { getValueWithDefault, getPhoneFormatValue } from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';
import {ACT_UPDATE_MANAGER} from '@/core/services/variable';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';


export default {
  name: "FarmManager",
  data(){
    return{
      i18n: {
        title: i18n.t('farm.manager.title'),
        manager: i18n.t('farm.manager.manager'),
        managerMobilePhone: i18n.t('farm.manager.managerMobilePhone'),
        managerEmail: i18n.t('farm.manager.managerEmail'),
        editTitle: i18n.t('farm.manager.editTitle'),
        edit: i18n.t('farm.manager.edit'),
        cancel: i18n.t('farm.manager.cancel'),
        editMessage: i18n.t('farm.manager.editMessage'),
      },
      managerModal: false,

      farmManagerName: '',
      farmManagerPhoneNo: '',
      farmManagerEmail:'',
    }
  },
  props: {
    manager: Object,
    fno: Number,
    farmDetailInit: Function
  },
  computed: {
    managerItems(){
      return [
        { title: this.i18n.manager, desc: getValueWithDefault(this.manager.farmManagerName, '-') },
        { title: this.i18n.managerMobilePhone, desc: getPhoneFormatValue(this.manager.farmManagerPhoneNo) },
        { title: this.i18n.managerEmail, desc: getValueWithDefault(this.manager.farmManagerEmail, '-') }
      ]
    }
  },
  methods: {
    managerEdit(){
      this.managerModal = true
      this.farmManagerName = this.manager.farmManagerName
      this.farmManagerPhoneNo = this.manager.farmManagerPhoneNo
      this.farmManagerEmail =this.manager.farmManagerEmail
    },
    saveManager(){
      let params ={
        farmManagerEmail: this.farmManagerEmail,
        farmManagerName: this.farmManagerName,
        farmManagerPhoneNo: this.farmManagerPhoneNo
      }
      this.$store.dispatch(ACT_UPDATE_MANAGER, {fno:this.fno, params: params}).then(()=> {
        this.$store.dispatch(ACT_ADD_ALERT, {message: `${this.i18n.editMessage}`, color: 'success'}).then(()=>{
          this.farmDetailInit()
        })
      })
      this.managerModal = false
    },
  },
};
</script>
