<template>
  <!--begin::Farm Scale-->
  <div class="card card-custom card-stretch gutter-b farm-info-scale">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.title}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Shopping/Sort3.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-light">
      <template v-for="(item, i) in scaleItems">
        <dl class="text-group row mb-4" v-bind:key="i">
            <dt class="title col-6">
              <span class="title font-weight-bold font-size-lg">{{ item.title }}</span>
            </dt>
            <dd class="description col-6">
              <span class="text">{{ item.desc }}</span>
            </dd>
        </dl>
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Scale-->
</template>

<script>
import {getValueWithDefault, getValueWithUnit} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "FarmScale",
  data() {
    return {
      i18n : {
          title: i18n.t('farm.scale.title'),
          locationName: i18n.t('farm.scale.locationName'),
          locationClassification: i18n.t('farm.scale.locationClassification'),
          siteArea: i18n.t('farm.scale.siteArea'),
          cultivationArea: i18n.t('farm.scale.cultivationArea'),
          envControllerCount: i18n.t('farm.scale.envControllerCount'),
          nutritionControllerCount: i18n.t('farm.scale.nutritionControllerCount'),
          num: i18n.t('farm.scale.num') ,
      }
    }
  },
  props:{
    scale: Object
  },
  computed: {
    scaleItems(){
      return [
        { title: this.i18n.locationName, desc: getValueWithDefault(this.scale.farmLocation, '-') },
        { title: this.i18n.locationClassification, desc: getValueWithDefault(this.scale.farmLocationGb, '-') },
        { title: this.i18n.siteArea, desc: getValueWithUnit(this.scale.farmArea, '㎡', true)},
        { title: this.i18n.cultivationArea, desc: getValueWithUnit(this.scale.farmCultivateArea, '㎡', true) },
        { title: this.i18n.envControllerCount, desc: getValueWithUnit(this.scale.envCtrlCount, this.i18n.num, true) },
        { title: this.i18n.nutritionControllerCount, desc: getValueWithUnit(this.scale.nutCtrlCount, this.i18n.num, true) }
      ]
    }
  }
};
</script>
