<template>
<div>
  <!--begin::List Widget 1-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-item">
    <!--begin::Header-->
    <div class="card-header bg-primary align-items-center border-0">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold text-white">
          {{ company.farmNm }}
        </span>
      </h3>
      <div class="card-toolbar">
        <span class="symbol symbol-30 symbol-light-dark">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Tools/Tools.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <template v-for="(item, i) in companyItems">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-4" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 symbol-light-dark mr-4" >
            <span class="symbol-label">
              <span
                class="svg-icon svg-icon-lg"
                v-bind:class="`svg-icon-${item.style}`"
              >
                <!--begin::Svg Icon-->
                <inline-svg :src="item.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column">
            <a href="#" class="farm-header font-weight-bolder text-dark text-hover-primary">
              {{ item.title }}
            </a>
            <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
      <div class="float-right"><button class="btn btn-secondary" @click="showCompanyModal()"><span class='icon-xs fas fa-pen'></span></button></div>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 1-->

  <!-- begin::companyModal -->
    <b-modal
      v-model="companyModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.companyManagement}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="companyModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.name}}</span>
          </label>
         <div class="col-sm-4">
            <input v-model="companyNm" type="text" class="farm-form-control form-control">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.representative}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="ceoNm" type="text" class="farm-form-control form-control">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.contactNumber}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="companyPhoneNo" type="number" class="farm-form-control form-control" placeholder="'-' 제외 숫자만 입력">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.date}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="startYmd" type="text" class="farm-form-control form-control" readonly="" value="">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.address}}</span>
          </label>
          <div class="col-sm-6">
            <input v-model="companyAddr" type="text" class="farm-form-control form-control" readonly="" value="">
          </div>
          <div class="col-sm-4">
            <button class="btn btn-secondary col-12" @click="searchAddress()">{{i18n.findAddress}}</button>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.detailedAddress}}</span>
          </label>
          <div class="col-sm-10">
            <input v-model="companyAddrDetail" type="text" class="farm-form-control form-control">
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveCompany()"><span>{{i18n.edit}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="[companyModal = false,farmDetailInit()]"><span class="">{{i18n.cancel}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::companyModal -->

    <!-- begin::addressModal -->
    <b-modal v-model="searchAddressModal"
      size="lg"
      :title="`${i18n.findAddress}`"
      :no-close-on-backdrop = 'false'
    >
      <div>
      <daum-postcode :onComplete="selectAddress"/>
      </div>
      <template #modal-footer>
      <div class="btn-wrap">
        <button type="button" class="btn btn-secondary" @click="searchAddressModal=false">Cancel</button>
      </div>
    </template>
    </b-modal>
    <!-- end::addressModal -->

</div>
</template>

<script>
import { ACT_GET_COMPANY, ACT_UPDATE_COMPANY } from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT, ACT_SHOW_ERROR_ALERT} from '@/core/services/store/alert.module';
import { phoneFormat, dateFormat, syncObj, getItem, lengthCheck, getResult, isSuccess} from '@/core/services/funcs';
import DaumPostcode from "vuejs-daum-postcode";
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmCompany",
  props: {
    company: Object,
    farmDetailInit: Function,
  },
  data() {
    return {
      i18n: {
        name: i18n.t('farm.company.name'),
        contactNumber: i18n.t('farm.company.contactNumber'),
        address: i18n.t('farm.company.address'),
        detailedAddress: i18n.t('farm.company.detailedAddress'),
        findAddress: i18n.t('farm.company.findAddress'),
        representative: i18n.t('farm.company.representative'),
        date: i18n.t('farm.company.date'),
        edit: i18n.t('farm.company.edit'),
        cancel: i18n.t('farm.company.cancel'),
        companyManagement: i18n.t('farm.company.companyManagement'),
        editted: i18n.t('farm.company.editted'),
        editQuestion: i18n.t('farm.company.editQuestion'),
        companyInvalidate: i18n.t('farm.company.companyInvalidate'),
        addressInvalidate: i18n.t('farm.company.addressInvalidate'),
        dateInvalidate: i18n.t('farm.company.dateInvalidate'),
      },
      companyModal : false,
      searchAddressModal : false,

      cno: 0,
      companyNm: '',
      companyPhoneNo: '',
      companyZipcode: '',
      companyAddr: '',
      companyAddrDetail: '',
      ceoNm: '',
      managerNm: '',
      managerPhoneNo: '',
      brnNo: '',
      memo: '',
      startYmd: '',
    };
  },
  components: { DaumPostcode },
  computed: {
    companyItems(){
      return [
        {
          title: this.company.companyNm,
          desc: this.i18n.name,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Building.svg",
          style: "primary"
        },
        {
          title: this.getPhoneFormat(this.company.companyPhoneNo),
          desc: this.i18n.contactNumber,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Adress-book1.svg",
          style: "primary"
        },
        {
          title: this.company.companyAddr + ' ' + (this.company.companyAddrDetail || ''),
          desc: this.i18n.address,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Map/Marker1.svg",
          style: "primary"
        },
        {
          title: (this.company.ceoNm || ''),
          desc: this.i18n.representative,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Clothes/Crown.svg",
          style: "primary"
        },
        {
          title: this.getDateFormat(this.company.companyStartYmd),
          desc: this.i18n.date,
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Code/Time-schedule.svg",
          style: "primary"
        }
      ]
    }
  },
  methods: {
    invalidateParams() {
      let message = ''
      let result = false
      if(!this.companyNm || !this.companyNm.trim()) {
        message = this.i18n.companyInvalidate
      }else if(!this.companyAddr){
        message = this.i18n.addressInvalidate
      }else if(!this.startYmd){
        message = this.i18n.dateInvalidate
      }else{
        result = true
      }
      if(!result) this.$store.dispatch(ACT_ADD_ALERT, {message: message, color: 'danger'});
      return result
    },
    updateCompany(params) {
      const yesCallback = () => {
        this.$store.dispatch(`${ACT_UPDATE_COMPANY}`, {cno :this.company.cno, data: params}).then(resp => {
          if(isSuccess(resp)){
            this.companyModal = false
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.companyNm + this.i18n.editted, color: 'success'}).then(()=>{
              this.farmDetailInit()
            })
          }else{
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
          }
        }).catch(e => {
          console.error(e)
          this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, null)
        })
      }

      this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
        info: {title: this.companyNm + this.i18n.editQuestion},
        callback: yesCallback,
        cancelCallback: this.cancelCallback
      })
    },
    saveCompany(){
      if(this.invalidateParams()){
        const params = {
          companyNm: this.companyNm,
          companyPhoneNo: (this.companyPhoneNo || '').replaceAll('.', '').replaceAll('-', ''),
          companyZipcode: this.companyZipcode,
          companyAddr: this.companyAddr,
          companyAddrDetail: this.companyAddrDetail,
          ceoNm: this.ceoNm,
          managerNm: this.managerNm,
          managerPhoneNo: (this.managerPhoneNo || '').replaceAll('.', '').replaceAll('-', ''),
          brnNo: this.brnNo,
          memo: this.memo,
          startYmd: this.startYmd.replaceAll('-', '')
        }
        this.updateCompany(params)
      }
    },
    selectAddress(a) {
      if(a){
        this.companyZipcode = a.zonecode
        this.companyAddr = a.address
      }
      this.searchAddressModal = false
    },
    showCompanyModal(){
      this.companyModal = true
      this.getCompany()
    },
    getCompany(cno){
      if(cno!=0){
        this.$store.dispatch(`${ACT_GET_COMPANY}`, this.company.cno).then(resp => {
          if(lengthCheck(resp)) syncObj(this, getItem(resp))
        })
      }else{
        this.$store.dispatch(`${ACT_GET_COMPANY}`, cno).then(resp => {
          if(lengthCheck(resp)) syncObj(this, getItem(resp))
        })
      }
    },
    getPhoneFormat(phoneNo){
      if(phoneNo){
        return phoneFormat(phoneNo);
      }
      return '-'
    },
    getDateFormat(ymd){
      if(ymd){
        return dateFormat(ymd, '.');
      }
      return '-'

    },
    searchAddress(){
      this.searchAddressModal = true
    },
  }
};
</script>
