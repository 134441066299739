<template>
  <!--begin::Farm Stats-->
  <div class="card card-custom card-stretch gutter-b farm-info-scale">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.title}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Shopping/Chart-line1.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-light">
      <template v-for="(item, i) in statsItems">
        <dl class="text-group row mb-4" v-bind:key="i">
            <dt class="title col-6">
              <span class="title font-weight-bold font-size-lg">{{ item.title }}</span>
            </dt>
            <dd class="description col-6">
              <span class="text">{{ item.desc }}</span>
            </dd>
        </dl>
      </template>
      <div class="float-right"><button class="btn btn-secondary" @click="statsEdit()"><span class='icon-xs fas fa-pen'></span></button></div>
    </div>
    <!--end::Body-->

    <!-- begin::statModal -->
    <b-modal
      v-model="statsModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.title}} {{i18n.edit}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="statsModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgProductionPerDay}}</span>
          </label>
         <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgProductionPerDay" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">kg</span>
              </div>
            </div>
          </div>
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgProductionPerWeek}}</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgProductionPerWeek" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">kg</span>
              </div>
            </div>
          </div>
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgProductionPerMonth}}</span>
          </label>
         <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgProductionPerMonth" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">kg</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgWaterUsagePerMonth}}</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgWaterUsagePerMonth" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{i18n.won}}</span>
              </div>
            </div>
          </div>
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgElectricityUsagePerMonth}}</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgElectricityUsagePerMonth" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{i18n.won}}</span>
              </div>
            </div>
          </div>
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.avgCO2UsagePerMonth}}</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <input v-model="avgCO2UsagePerMonth" type="Number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{i18n.won}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveStats()"><span>{{i18n.edit}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="statsModal = false"><span class="">{{i18n.cancel}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::statModal -->
  </div>
  <!--end: Farm Stats-->
</template>

<script>
import {ACT_UPDATE_STATISTICS, } from '@/core/services/variable';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module'
import {getValueWithUnit, isSuccess} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "FarmStats",
  data(){
    return {
      i18n: {
        title: i18n.t('farm.statistics.title'),
        avgProductionPerDay: i18n.t('farm.statistics.avgProductionPerDay'),
        avgProductionPerWeek: i18n.t('farm.statistics.avgProductionPerWeek'),
        avgProductionPerMonth: i18n.t('farm.statistics.avgProductionPerMonth'),
        avgWaterUsagePerMonth: i18n.t('farm.statistics.avgWaterUsagePerMonth'),
        avgElectricityUsagePerMonth: i18n.t('farm.statistics.avgElectricityUsagePerMonth'),
        avgCO2UsagePerMonth: i18n.t('farm.statistics.avgCO2UsagePerMonth'),
        won: i18n.t('farm.statistics.won'),
        edit: i18n.t('farm.statistics.edit'),
        cancel: i18n.t('farm.statistics.cancel'),
        editMessage: i18n.t('farm.statistics.editMessage')
      },
      statsModal: false,

      avgProductionPerDay: 0,
      avgProductionPerWeek: 0,
      avgProductionPerMonth: 0,
      avgWaterUsagePerMonth: 0,
      avgElectricityUsagePerMonth: 0,
      avgCO2UsagePerMonth: 0,

    }
  },
  props: {
    stats: Object,
    fno: Number,
    farmDetailInit: Function
  },
  computed: {
    statsItems(){
      return [
        { title: this.i18n.avgProductionPerDay, desc: getValueWithUnit(this.stats.farmAvgProductionPDay, 'kg', true) },
        { title: this.i18n.avgProductionPerWeek, desc: getValueWithUnit(this.stats.farmAvgProductionPWeek, 'kg', true) },
        { title: this.i18n.avgProductionPerMonth, desc: getValueWithUnit(this.stats.farmAvgProductionPMonth, 'kg', true) },
        { title: this.i18n.avgWaterUsagePerMonth, desc: getValueWithUnit(this.stats.waterAvgUsage, `${i18n.t('farm.statistics.won')}`, true) },
        { title: this.i18n.avgElectricityUsagePerMonth, desc: getValueWithUnit(this.stats.electricAvgUsage, `${i18n.t('farm.statistics.won')}`, true) },
        { title: this.i18n.avgCO2UsagePerMonth, desc: getValueWithUnit(this.stats.co2AvgUsage, `${i18n.t('farm.statistics.won')}`, true) }
      ]
    }
  },
  methods:{
    statsEdit(){
      this.statsModal = true
      this.avgProductionPerDay= this.stats.farmAvgProductionPDay
      this.avgProductionPerWeek= this.stats.farmAvgProductionPWeek
      this.avgProductionPerMonth= this.stats.farmAvgProductionPMonth
      this.avgWaterUsagePerMonth= this.stats.waterAvgUsage
      this.avgElectricityUsagePerMonth= this.stats.electricAvgUsage
      this.avgCO2UsagePerMonth= this.stats.co2AvgUsage
    },
    saveStats(){
      const params = {
        co2AvgUsage:  this.avgCO2UsagePerMonth,
        electricAvgUsage:  this.avgElectricityUsagePerMonth,
        farmAvgProductionPDay:  this.avgProductionPerDay,
        farmAvgProductionPMonth:  this.avgProductionPerMonth,
        farmAvgProductionPWeek: this.avgProductionPerWeek,
        waterAvgUsage:  this.avgWaterUsagePerMonth
      }
      this.$store.dispatch(ACT_UPDATE_STATISTICS, {fno: this.fno, params: params}).then((resp)=>{
        if(isSuccess(resp)){
          this.$store.dispatch(ACT_ADD_ALERT, {message: `${this.i18n.editMessage}`, color: 'success'}).then(()=>{
            this.farmDetailInit()
          })
        }
      })
      this.statsModal = false
    }
  }
};
</script>
