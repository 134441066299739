
<template>
  <div class="page-farm-info">
    <!--begin::Farm Info-->
    <div class="row">
      <div class="col-md-12 col-xxl-3">
        <farm-company
            :company="company"
            :farm-detail-init="farmDetailInit"
        ></farm-company>
      </div>
      <div class="col-md-12 col-xxl-9">
        <farm-basic
            :info="info"
            :map="map"
            :effect-value="effectValue"
        ></farm-basic>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-scale
            :scale="scale"
        ></farm-scale>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-stats
            :stats="stats"
            :fno="fno"
            :farm-detail-init="farmDetailInit"
        ></farm-stats>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-facility
            :facility="facility"
        ></farm-facility>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-manager
            :manager="manager"
            :fno="fno"
            :farmDetailInit="farmDetailInit"
        ></farm-manager>
      </div>
      <div :class="`col-md-${indexNo} col-xxl-${indexNo}`">
        <farm-specificity-information
            :farmSpecificityInformation="farmSpecificityInformation"
            :fno='fno'
            :farm-detail-init="farmDetailInit"
        ></farm-specificity-information>
      </div>
      <div v-if="drawing && drawing.length" :class="`col-md-${indexNo} col-xxl-${indexNo}`">
        <farm-drawing
            :drawing="drawing"
        ></farm-drawing>
      </div>
      <div v-if="resources && resources.length" :class="`col-md-${indexNo} col-xxl-${indexNo}`">
        <farm-resources
            :resource="resources"
        ></farm-resources>
      </div>
      <div class="col-xxl-12">
        <farm-crops
            :crops="crops"
        ></farm-crops>
      </div>
      <div class="col-xxl-12">
        <farm-device
            :machines="machines"
        ></farm-device>
      </div>
      <div class="col-xxl-12">
        <farm-connection
            :connects="connects"
        ></farm-connection>
      </div>
    </div>
    <!--end::Farm Info-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {ACT_GET_MY_FARM, MUT_SET_FARM_DETAIL} from '@/core/services/variable';
import FarmCompany from "@/view/content/farm/FarmCompany.vue";
import FarmBasic from "@/view/content/farm/FarmBasic.vue";
import FarmScale from "@/view/content/farm/FarmScale.vue";
import FarmStats from "@/view/content/farm/FarmStats.vue";
import FarmFacility from "@/view/content/farm/FarmFacility.vue";
import FarmManager from "@/view/content/farm/FarmManager.vue";
import FarmDrawing from "@/view/content/farm/FarmDrawing.vue";
import FarmResources from "@/view/content/farm/FarmResources.vue";
import FarmCrops from "@/view/content/farm/FarmCrops.vue";
import FarmDevice from "@/view/content/farm/FarmDevice.vue";
import FarmConnection from "@/view/content/farm/FarmConnection.vue";
import FarmSpecificityInformation from "@/view/content/farm/FarmSpecificityInformation.vue"
import {lengthCheck, getItem, syncObj2, collectionsCheck } from '@/core/services/funcs';
import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmDetail",
  components: {
    FarmCompany,
    FarmBasic,
    FarmScale,
    FarmStats,
    FarmFacility,
    FarmManager,
    FarmDrawing,
    FarmResources,
    FarmCrops,
    FarmDevice,
    FarmConnection,
    FarmSpecificityInformation
  },
  beforeMount() {
    this.farmDetailInit(0)
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.farm.info'), icon: 'fas fa-clipboard' }, { title:  i18n.t("topNavBar.farm.search") }]);
    window.scrollTo(0,0);
  },
  computed: {
    ...mapGetters(["farmDetail"]),
  },
  data() {
    return {
      company: {
        farmNm: '',
        companyNm : '',
        companyCd : '',
        companyPhoneNo : '',
        companyAddr : '',
        companyAddrDetail : '',
        ceoNm : '',
        companyStartYmd : '',
        cno: '',
      },
      info: {
        farmNm: '',
        farmCd: '',
        sidoGb: '',
        farmAddr: '',
        companyNm: '',
        cultivateType: '',
        farmCultivateArea: '',
        operationSt: '',
        farmOwnerPhoneNo: '',
        farmCultivateCrops: null,
      },
      // Map
      map: {
        latitude: '0',
        longitude: '0',
      },
      effectValue: 0,
      // scale
      scale: {
        farmLocation: '',
        farmLocationGb: '',
        farmArea: '',
        farmCultivateArea: '',
        envCtrlCount: 0,
        nutCtrlCount: 0,
      },
      // stats
      stats: {
        farmAvgProductionPDay: '',
        farmAvgProductionPWeek: '',
        waterAvgUsage: '',
        electricAvgUsage: '',
        farmAvgProductionPMonth: '',
        co2AvgUsage: ''
      },
      // facility
      facility: {
        layerCount: 0,
        rowCount: 0,
        rackType1: '',
        rackType2: '',
        rackType3: '',
        rackType4: '',
        rackType5: '',
        bedStandards: 0,
        bedCount: 0,
        rackCount: 0,
        seedRackCount: 0,
        transplantRackCount: 0,
        lastfeedRackCount: 0,
        potCount: 0,
      },
      manager: {
        farmManagerName: '',
        farmManagerPhoneNo: '',
        farmManagerEmail: '',
      },
      drawing: [],
      resources: [],

      crops: [],
      machines: [],
      connects: [],
      farmSpecificityInformation: [],
      indexNo: 1,
      fno: 0,
    }
  },
  methods: {
    farmDetailInit(parent){
      if(this.farmDetail != null && parent != undefined){
        this.setFarmDetail(this.farmDetail);
      }else{
        this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
          if(lengthCheck(resp)) {
            const item = getItem(resp);
            this.$store.commit(MUT_SET_FARM_DETAIL, item);
            this.setFarmDetail(item);
          }
        });
      }
    },
    setFarmDetail(item) {
      syncObj2(this.company, item);
      syncObj2(this.info, item);
      syncObj2(this.map, item);
      syncObj2(this.scale, item);
      syncObj2(this.stats, item);
      syncObj2(this.facility, item);
      syncObj2(this.manager, item);
      this.farmSpecificityInformation = item.farmSpecificityInformation.sort((a,b)=>{
        return b.farmSpecificityInformationNo - a.farmSpecificityInformationNo;
      })
      this.fno = item.fno

      // 파일 처리
      if(item.farmFiles){
        this.drawing = []
        this.resources = []
        item.farmFiles.forEach(file => {
          if(file.attachTypeDcd === '1009001'){
            this.drawing.push(file)
          }else{
            this.resources.push(file)
          }
        })
      }
      if(collectionsCheck(item.farmMachines, 'seq')) this.machines = item.farmMachines;
      if(collectionsCheck(item.farmCultivateCrops, 'cultivateCropSeq')) this.crops = item.farmCultivateCrops;
      // if(collectionsCheck(item.farmCultivateCrops, 'cultivateCropSeq')) this.crops = []
      this.connects = item.farmConnects;

      setTimeout(() => {
        this.effectValue++;
      }, 500);

      this.indexNo = 1
      if(this.drawing && this.drawing.length){this.indexNo +=1}
      if(this.resources && this.resources.length){this.indexNo +=1}
      this.indexNo = 12/this.indexNo
    }
  }
};
</script>
