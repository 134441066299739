<template>
  <div class="card card-custom card-stretch gutter-b farm-info-crops">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bold text-dark">{{i18n.title}}</span>
          <span class="subtext text-muted mt-3 font-weight-bold font-size-sm">{{i18n.total}} {{ cropCount }}{{i18n.cropsCount}}</span>
        </h3>
      </div>

      <div class="card-toolbar toolbar-symbol">
        <router-link
            :to="'crops'"
            v-slot="{ href, navigate }"
        >
          <b-button variant="dark" class="rounded-sm" size="sm" @click="navigate">{{i18n.register}}</b-button>
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">

      <v-data-table
          :headers="fields"
          :items="cropItems"
          :items-per-page="10"
          class="elevation-1 farm-data-table"
          :no-data-text='i18n.noData'
      >
        <template v-slot:[`item.fieldD`]="{ item }">
          {{ item.fieldD ? item.fieldD : '-' }}
        </template>
      </v-data-table>

    </div>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmCrops",
  props: {
    crops: Array
  },
  data() {
    return {
        // fields: [
        //   {class: 'text-center', key: 'idx', label: '순번'},
        //   {class: 'text-center', key: 'fieldA', label: '작물명'},
        //   {class: 'text-center', key: 'fieldB', label: '재배 작물 유형'},
        //   {class: 'text-center', key: 'fieldC', label: '재배 환경 기준'},
        //   {class: 'text-center', key: 'fieldD', label: '제어기 연결'}
        // ]
      i18n:{
        title: i18n.t('farm.cultivationCrop.title'),
        register: i18n.t('farm.cultivationCrop.register'),
        total: i18n.t('farm.cultivationCrop.total'),
        cropsCount: i18n.t('farm.cultivationCrop.cropsCount'),
        noData : i18n.t('farm.cultivationCrop.noData'),
      },  

      fields: [
        { value: 'idx', text: i18n.t('farm.cultivationCrop.numbering')},
        { value: 'fieldA', text: i18n.t('farm.cultivationCrop.cropName')},
        { value: 'fieldB', text: i18n.t('farm.cultivationCrop.cultivationCropType')},
        { value: 'fieldC', text: i18n.t('farm.cultivationCrop.cultivationEnvStandard')},
        { value: 'fieldD', text: i18n.t('farm.cultivationCrop.controllerConnection')}
      ]
    };
  },
  computed: {
    cropItems(){
      const items = [];
      this.crops.forEach((crop, idx) => {
        items.push({
          'idx': (idx + 1), 'fieldA': crop.cropNm, 'fieldB': crop.cropType, 'fieldC': crop.baseNm, 'fieldD': crop.cropConnectNm
        });
      });
      return items;
    },
    cropCount(){
      if(this.crops) return this.crops.length;
      return 0
    }
  }
};
</script>
