var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch gutter-b farm-info-connection"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"card-title top"},[_c('h3',{staticClass:"card-label"},[_c('span',{staticClass:"d-block font-weight-bold text-dark"},[_vm._v(_vm._s(_vm.i18n.title))]),_c('span',{staticClass:"subtext text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(_vm._s(_vm.i18n.total)+" "+_vm._s(_vm.connectCount)+_vm._s(_vm.i18n.controllerCount))])])]),_c('div',{staticClass:"card-toolbar toolbar-symbol"},[_c('router-link',{attrs:{"to":'setting'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"rounded-sm",attrs:{"variant":"dark","size":"sm"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.i18n.setting))])]}}])})],1)]),_c('div',{staticClass:"card-body pt-0"},[_c('v-data-table',{staticClass:"elevation-1 farm-data-table",attrs:{"headers":_vm.fields,"items":_vm.connectItems,"items-per-page":10,"no-data-text":"No Data"},scopedSlots:_vm._u([{key:"item.fieldB",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fieldB ? item.fieldB : '-')+" ")]}},{key:"item.fieldD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fieldD ? item.fieldD : '-')+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }