<template>
  <!--begin::Farm Manager-->
  <div class="card card-custom card-stretch gutter-b farm-info-scale">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
            {{i18n.farmSpecificityInformation}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <span class='icon-xl fas fa-info-circle text-primary'></span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-light">
      <template v-for="(item, i) in farmSpecificityInformation">
        <dl class="text-group row mb-4" v-bind:key="i">
            <dt class="title col-2">
              <span class="title font-weight-bold font-size-lg">{{ item.specificity }}</span>
            </dt>
            <dd class="description col-10">
              <span class="text">{{ item.description }}</span>
            </dd>
        </dl>
      </template>
      <div class="float-right"><button class="btn btn-secondary" @click="sepcificityInfoEdit()"><span class='icon-xs fas fa-pen'></span></button></div>
    </div>
    <!--end::Body-->

    <!-- begin::specificityInfoModal -->
    <b-modal
      v-model="specificityInfoModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.farmSpecificityInformation}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button class="btn btn-dark rounded" size="sm" @click="showEditModal()">
            {{i18n.register}}
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <v-data-table
          :headers="fields"
          :items="specificityInfoItems"
          class="elevation-1 farm-data-table"
          no-data-text="No Data"
        >
        <template v-slot:[`item.fieldD`]="{ item }">
                <b-button size="sm" variant="outline-dark" @click="showEditModal(item)">
                  <span class='icon-xs fas fa-pen mr-2'></span>
                  <span class="font-weight-bold">{{i18n.edit}}</span>
                </b-button>
                <b-button size="sm" variant="dark" class="rounded-sm" @click="showDelModal(item)">
                  <span class="fas fa-trash font-size-sm mr-2"></span>
                  <span class="text font-weight-normal">{{i18n.delete}}</span>
                </b-button>
              </template>
        </v-data-table>
      <!-- end::Body -->
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="secondary float-right" size="lg" class="ml-3" @click="[specificityInfoModal = false, farmDetailInit()]"><span>{{i18n.cancel}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::specificityInfoModal -->

    <!-- begin::addInfoModal -->
    <b-modal
      v-model="addInfoModal"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.farmSpecificityInformation}} {{this.isUpdate==false ? i18n.register :  i18n.edit}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="addInfoModal=false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.specificity}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="specificity" :options="specificityOptions"></b-form-select>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.tag}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-tags v-model="tag" type="text" :placeholder="`${i18n.tagPlaceholder}`"></b-form-tags>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.description}}</span>
          </label>
         <div class="col-sm-10">
            <b-form-textarea
              v-model="description"
              type="text"
              id="textarea-auto-height"
              :placeholder="`${i18n.specificityPlaceholder}`"
              rows="5"
              max-rows="20"
            ></b-form-textarea>
          </div>
          
        </div>
      </div>
      <!-- end::Body -->
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveInfo()"><span>{{isUpdate==false ? i18n.register : i18n.edit}}</span></b-button>
            <b-button variant="secondary float-right" size="lg" class="ml-3" @click="[addInfoModal = false,farmDetailInit()]"><span class="">{{i18n.cancel}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::addInfoModal -->
  </div>
  <!--end: Farm Manager-->
</template>

<script>
import {ACT_SHOW_ERROR_ALERT, ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module'; 
import { ACT_INSERT_SPECIFICITY_INFO, ACT_UPDATE_SPECIFICITY_INFO, ACT_DELETE_SPECIFICITY_INFO} from '@/core/services/variable';
import { isSuccess } from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
    props:{
      farmSpecificityInformation: Array,
      fno : Number,
      farmDetailInit: Function
    },
    computed:{
        specificityInfoItems(){
            const items = []
            this.farmSpecificityInformation.forEach((item, idx)=>{
                let tags = []
                item.tag.forEach(tag =>{
                    tags.push(tag.tag)
                })
                items.push({
                    'idx' : idx + 1, 'fieldA' : item.specificity, 'fieldB' : item.description,
                    'fieldC' : tags, 'farmSpecificityInformationNo' : item.farmSpecificityInformationNo
                })
            })
            return items
        },
    },
    data(){
        return{
            fields: [
                { value: 'idx', text: i18n.t('farm.farmSpecificityInformation.numbering') },
                { value: 'fieldA', text: i18n.t('farm.farmSpecificityInformation.specificy') },
                { value: 'fieldB', text: i18n.t('farm.farmSpecificityInformation.description') },
                { value: 'fieldC', text: i18n.t('farm.farmSpecificityInformation.tag') },
                { value: 'fieldD', text: "" },
            ],
            specificityInfoModal : false,
            addInfoModal: false,

            specificity: null,
            specificityOptions: [{ value: null, text: '' },{value: 1, text: '팜'},{value: 2, text: '설비'},{value: 3, text: '작물'},{value: 4, text: '양액'}],
            tag: [],
            description: '',
            isUpdate: false,
            farmSpecificityInformationNo: 0,
            i18n: {
              farmSpecificityInformation: i18n.t('farm.farmSpecificityInformation.farmSpecificityInformation'),
              register: i18n.t('farm.farmSpecificityInformation.register'),
              numbering: i18n.t('farm.farmSpecificityInformation.numbering'),
              specificity: i18n.t('farm.farmSpecificityInformation.specificy'),
              description: i18n.t('farm.farmSpecificityInformation.description'),
              tag: i18n.t('farm.farmSpecificityInformation.tag'),
              edit: i18n.t('farm.farmSpecificityInformation.edit'),
              delete: i18n.t('farm.farmSpecificityInformation.delete'),
              cancel: i18n.t('farm.farmSpecificityInformation.cancel'),
              tagPlaceholder: i18n.t('farm.farmSpecificityInformation.tagPlaceholder'),
              specificityPlaceholder: i18n.t('farm.farmSpecificityInformation.specificityPlaceholder'),
              deleted: i18n.t('farm.farmSpecificityInformation.deleted'),
              deleteQuestion: i18n.t('farm.farmSpecificityInformation.deleteQuestion'),
              invalidvalue: i18n.t('farm.farmSpecificityInformation.invalidvalue'),
              saved: i18n.t('farm.farmSpecificityInformation.saved'),
              edited: i18n.t('farm.farmSpecificityInformation.edited'),
            },
        }
    },
    methods: {
        sepcificityInfoEdit(){
          this.specificityInfoModal = true
        },
        showEditModal(item){
          this.addInfoModal = true
          this.specificity = null
          this.tag = []
          this.description = ''
          this.isUpdate = false

          if(item != undefined){
            this.isUpdate = true
            this.specificityOptions.forEach(spec =>{
              if(spec.text == item.fieldA){
                this.specificity = spec.value
              }
            })
            this.tag = item.fieldC
            this.description = item.fieldB
            this.farmSpecificityInformationNo = item.farmSpecificityInformationNo
          }
        },
        showDelModal(item){
          const params = {
            farmSpecificityInformationNo: item.farmSpecificityInformationNo, 
            fno: this.fno
          }
          const yesCallback = () => {
            this.$store.dispatch(ACT_DELETE_SPECIFICITY_INFO, params).then(resp => {
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.deleted, color: 'success'}).then(()=>{
                this.farmDetailInit()
              })
            }else{
              this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
            }
          }).catch(e => {
            console.error(e)
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, null)
          })
          }

          this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
            info: {title: this.i18n.deleteQuestion},
            callback: yesCallback
          })
        },
        saveInfo(){
          if(this.specificity == null || this.description == ""){
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, this.i18n.invalidvalue)
            return
          }

          const tagJson = []
          this.tag.forEach(tag =>{
            tagJson.push({tag: tag})
          })
          let tagStringfy = JSON.stringify(tagJson)
          let params = {
            "farmSpecificityInformationJson": JSON.stringify({specificity: this.specificityOptions[this.specificity].text, description: this.description, tagJson: tagStringfy}),
            "fno": this.fno,
          }

          if(this.isUpdate != true){
            this.$store.dispatch(`${ACT_INSERT_SPECIFICITY_INFO}`, params).then(resp => {
              if(isSuccess(resp)){
                this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.saved, color: 'success'}).then(()=>{
                  this.farmDetailInit()
                })
              }else{
                this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
              }
            })
          }else{
            params.farmSpecificityInformationNo = this.farmSpecificityInformationNo
            this.$store.dispatch(`${ACT_UPDATE_SPECIFICITY_INFO}`, params).then(resp => {
              if(isSuccess(resp)){
                this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.edited, color: 'success'}).then(()=>{
                  this.farmDetailInit()
                })
              }else{
                this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
              }
            })
          }

          this.addInfoModal = false
        },
    },
}
</script>