<template>
  <!--begin::Farm Drawing-->
  <div class="card card-custom card-stretch gutter-b farm-info-drawing">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.floorPlan}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/PenAndRuller.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-img v-for="(item, idx) in firstDrawing" :key="idx"
             :src="item.filePath" fluid-grow alt="Fluid-grow image"
      ></b-img>
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Drawing-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';
export default {
  name: "FarmDrawing",
  props: {
    drawing: Array
  },
  data() {
    return {
      i18n: {
        floorPlan: i18n.t('farm.floorPlan'),
      },
    };
  },
  computed: {
    firstDrawing(){
      if(this.drawing && this.drawing.length > 0){
        return [this.drawing[0]];
      }
      return [];
    }
  }
};
</script>
