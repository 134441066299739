<template>
  <!--begin::Farm Device-->
  <div class="card card-custom card-stretch gutter-b farm-info-device">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bold text-dark">{{i18n.title}}</span>
          <span class="subtext text-muted mt-3 font-weight-bold font-size-sm">{{i18n.total}} {{ machineCount }}{{i18n.deviceCount}}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <v-data-table
          :headers="fields"
          :items="machineItems"
          :items-per-page="10"
          class="elevation-1 farm-data-table"
          no-data-text="No Data"
      >
        <template v-slot:[`item.fieldA`]="{ item }">
          {{ item.fieldA ? item.fieldA : '-' }}
        </template>
        <template v-slot:[`item.fieldC`]="{ item }">
          {{ item.fieldC ? item.fieldC : '-' }}
        </template>
        <template v-slot:[`item.fieldF`]="{ item }">
          {{ item.fieldF ? item.fieldF : '-' }}
        </template>
      </v-data-table>

<!--      <b-table -->
<!--        responsive-->
<!--        :items="machineItems"-->
<!--        :fields="fields"-->
<!--        head-variant="light"-->
<!--        class="table-vertical-center border-bottom"-->
<!--        :empty-text="'No Data'"-->
<!--        show-empty-->
<!--      >-->
<!--      <template #table-colgroup="scope">-->
<!--        <col-->
<!--          v-for="field in scope.fields"-->
<!--          :key="field.key"-->
<!--          :style="{ width: field.key === 'idx' ? '70px' : 'auto' }"-->
<!--        >-->
<!--      </template>-->
<!--      </b-table>-->
      <!--begin::pagination-->
<!--      <div class="pagination mt-10">-->
<!--        <b-pagination-nav-->
<!--          number-of-pages="5"-->
<!--          base-url="#"-->
<!--        >-->
<!--          <template #first-text><i class="fas fa-angle-double-left"></i></template>-->
<!--          <template #prev-text><i class="fas fa-angle-left"></i></template>-->
<!--          <template #next-text><i class="fas fa-angle-right"></i></template>-->
<!--          <template #last-text><i class="fas fa-angle-double-right"></i></template> -->
<!--          <template #page="{ page, active }">-->
<!--            <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>-->
<!--            <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>-->
<!--          </template>-->
<!--        </b-pagination-nav>-->
<!--      </div>-->
      <!--end::pagination-->
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Device-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmDevice",
  props: {
    machines: Array
  },
  data() {
    return {
      i18n: {
        title: i18n.t('farm.device.title'),
        total: i18n.t('farm.device.total'),
        deviceCount: i18n.t('farm.device.deviceCount'),
      },
        // fields: [
        //   { class: 'text-center', key: 'idx', label: '순번' },
        //   { class: 'text-center', key: 'fieldA', label: '장치구분' },
        //   { class: 'text-center', key: 'fieldB', label: '장치유형' },
        //   { class: 'text-center', key: 'fieldC', label: '장치항목' },
        //   { class: 'text-center', key: 'fieldD', label: '제품명' },
        //   { class: 'text-center', key: 'fieldE', label: '장치상태' },
        //   { class: 'text-center', key: 'fieldF', label: '제어기 연결' },
        // ]
      fields: [
        { value: 'idx', text: i18n.t('farm.device.numbering') },
        { value: 'fieldA', text: i18n.t('farm.device.deviceClassification') },
        { value: 'fieldB', text: i18n.t('farm.device.deviceType') },
        { value: 'fieldC', text: i18n.t('farm.device.subDeviceType') },
        { value: 'fieldD', text: i18n.t('farm.device.deviceName') },
        { value: 'fieldE', text: i18n.t('farm.device.deviceStatus') },
        { value: 'fieldF', text: i18n.t('farm.device.controllerConnection') },
      ]
    };
  },
  computed: {
    machineItems(){
      const items = [];
      this.machines.forEach((machine, idx) => {
        items.push({
          'idx': idx + 1, 'fieldA': machine.farmMachineNm, 'fieldB': machine.equipType, 'fieldC': machine.subEquipType,
          'fieldD': machine.machineNm, 'fieldE': machine.machineSt, 'fieldF': machine.machineConnectNm
        })
      });
      return items
    },
    machineCount(){
      if(this.machines) return this.machines.length;
      return 0
    }
  }
};
</script>
