<template>
  <!--begin::Farm Connection-->
  <div class="card card-custom card-stretch gutter-b farm-info-connection">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bold text-dark">{{i18n.title}}</span>
          <span class="subtext text-muted mt-3 font-weight-bold font-size-sm">{{i18n.total}} {{ connectCount }}{{i18n.controllerCount}}</span>
        </h3>
      </div>
      <div class="card-toolbar toolbar-symbol">
        <router-link
            :to="'setting'"
            v-slot="{ href, navigate }"
        >
          <b-button variant="dark" class="rounded-sm" size="sm" @click="navigate">{{i18n.setting}}</b-button>
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <v-data-table
          :headers="fields"
          :items="connectItems"
          :items-per-page="10"
          class="elevation-1 farm-data-table"
          no-data-text="No Data"
      >
        <template v-slot:[`item.fieldB`]="{ item }">
          {{ item.fieldB ? item.fieldB : '-' }}
        </template>
        <template v-slot:[`item.fieldD`]="{ item }">
          {{ item.fieldD ? item.fieldD : '-' }}
        </template>

      </v-data-table>
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Connection-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmConnection",
  props: {
    connects: Array
  },
  data() {
    return {
      i18n:{
        title: i18n.t('farm.controller.title'),
        total: i18n.t('farm.controller.total'),
        controllerCount: i18n.t('farm.controller.controllerCount'),
        setting: i18n.t('farm.controller.setting'),
      },
        // fields: [
        //   { class: 'text-center', key: 'idx', label: '순번'},
        //   { class: 'text-center', key: 'fieldA', label: '제어기 명'},
        //   { class: 'text-center', key: 'fieldB', label: '제이기 코드'},
        //   { class: 'text-center', key: 'fieldC', label: '제어기 유형'},
        //   { class: 'text-center', key: 'fieldD', label: '재배 유형'},
        //   { class: 'text-center', key: 'fieldE', label: '인터넷 연결 유무'},
        //   { class: 'text-center', key: 'fieldF', label: '시스템 ID'}
        // ]
      fields: [
        { value: 'idx', text: i18n.t('farm.controller.numbering')},
        { value: 'fieldA', text: i18n.t('farm.controller.controllerName')},
        { value: 'fieldB', text: i18n.t('farm.controller.controllerCode')},
        { value: 'fieldC', text: i18n.t('farm.controller.controllerType')},
        { value: 'fieldD', text: i18n.t('farm.controller.culturalType')},
        { value: 'fieldE', text: i18n.t('farm.controller.isInternetConnected')},
        { value: 'fieldF', text: i18n.t('farm.controller.systemId')}
      ]
    };
  },
  computed: {
    connectItems(){
      const items = [];
      this.connects.forEach((connect, idx) => {
        items.push({
          'idx': (idx + 1), 'fieldA': connect.connectNm, 'fieldB': connect.connectCd, 'fieldC': connect.envCtrlType,
          'fieldD': connect.growType, 'fieldE': connect.internetConnectYn,
          'fieldF': connect.systemId,
        });
      });
      return items;
    },
    connectCount(){
      if(this.connects) return this.connects.length;
      return 0
    }
  }
};
</script>
