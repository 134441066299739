<template>
  <!--begin::Farm Drawing-->
  <div class="card card-custom card-stretch gutter-b farm-info-resources">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.references}}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <span class="symbol symbol-40 symbol-light-dark rounded-sm">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Image.svg" />
            </span>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="resource-list">
        <!--resource-item-->
        <template v-for="(item, idx) in resource">
          <div v-if="item.attachTypeDcd === '1009002'" class="resource-item" :key="idx">
            <div class="resource-container">
            <span class="icon-file">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_doc.svg" />
            </span>
              <span class="text">{{i18n.document}}</span>
            </div>
          </div>
          <div v-else-if="item.attachTypeDcd === '1009004'" class="resource-item" :key="idx">
            <div class="resource-container">
            <span class="icon-file">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_video.svg" />
            </span>
              <span class="text">{{i18n.video}}</span>
            </div>
          </div>
          <div v-else-if="item.attachTypeDcd === '1009005'" class="resource-item" :key="idx">
            <div class="resource-container">
            <span class="icon-file">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_etc.svg" />
            </span>
              <span class="text">{{i18n.etc}}</span>
            </div>
          </div>
          <div v-else :key="idx" class="resource-item">
            <b-img :src="item.filePath" fluid-grow alt="Fluid-grow image"></b-img>
          </div>
        </template>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end: Farm Resources-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmResources",
  props: {
    resource: Array
  },
  computed: {
  },
  data(){
    return{
      i18n: {
        references: i18n.t('farm.farmResources.references'),
        document: i18n.t('farm.farmResources.document'),
        video: i18n.t('farm.farmResources.video'),
        etc: i18n.t('farm.farmResources.etc'),
      },
    }
  }
};
</script>
